<template>
    <div class="Projectstatistics">
        <el-row :gutter="24">
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <div class="chart_card_header">
                        <div class="meta">
                            <span>项目总数</span>
                        </div>
                        <!-- <div class="total">
                            <span>60</span>
                        </div> -->
                    </div>
                    <div class="chart_card_content">
                        <div class="content_fix">
                            <!-- <div> -->
                                <ve-pie
                                    :data="chartData" 
                                    :extend="chartExtend"
                                    :settings="chartSettings"
                                    :legend-visible="false"
                                    judge-width
                                    height="170px"
                                ></ve-pie>
                            <!-- </div> -->
                            <!-- <div>
                                <div class="ant_pro_trend">
                                    <span><span>国家级:</span><span class="item_text"> 30 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>省级:</span><span class="item_text"> 20 </span></span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span><span>校级:</span><span class="item_text"> 10 </span></span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="chart_card_footer">
                        <div class="field">
                            本年新增:<span>16</span>
                        </div>
                    </div> -->
                </div>
             </el-col>
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <div class="chart_card_header">
                        <div class="meta">
                            <span>已结项</span>
                        </div>
                        <!-- <div class="total">
                            <span>30</span>
                        </div> -->
                    </div>
                    <div class="chart_card_content">
                        <div class="content_fix">
                            <ve-pie
                                :data="chartData" 
                                :extend="chartExtend"
                                :settings="chartSettings"
                                :legend-visible="false"
                                judge-width
                                height="170px"
                            ></ve-pie>
                            <!-- <div>
                                <div class="ant_pro_trend">
                                    <span>
                                        <span>国家级:</span>
                                        <span class="item_text"> 15 </span>
                                    </span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span>
                                        <span>省级:</span>
                                        <span class="item_text"> 10 </span>
                                    </span>
                                </div>
                                <div class="ant_pro_trend">
                                    <span>
                                        <span>校级:</span>
                                        <span class="item_text"> 5 </span>
                                    </span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>  
             </el-col>
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <div class="chart_card_header">
                        <div class="meta">
                            <span>待检查</span>
                        </div>
                    </div>
                    <div class="chart_card_content">
                        <ve-pie
                                :data="chartData" 
                                :extend="chartExtend"
                                :settings="chartSettings"
                                :legend-visible="false"
                                judge-width
                                height="170px"
                            ></ve-pie>
                    </div>
                </div>
             </el-col>
             <el-col
                :xs="{span: 24}"
                :span="12"
                :lg="{span: 6}"
                class="margin-bottom-24">
                <div class="page_content">
                    <div class="chart_card_header">
                        <div class="meta">
                            <span>过期项目</span>
                        </div>
                    </div>
                    <div class="chart_card_content">
                        <ve-pie
                                :data="chartData" 
                                :extend="chartExtend"
                                :settings="chartSettings"
                                :legend-visible="false"
                                judge-width
                                height="170px"
                            ></ve-pie>
                    </div>
                </div>
             </el-col>
        </el-row>
        <el-card style="border:none;box-shadow:none;position: relative;">
            <el-tabs style="margin-bottom: -20px">
                <el-tab-pane 
                    value="sales" 
                    lazy>
                    <span
                    slot="label"
                    class="font-16 page-tabs-label">项目统计</span>
                    <el-row>
                        <el-col
                            :xs="{span:24}"
                            :sm="{span:24}"
                            :md="{span:24}"
                            :lg="{span:24}"
                            :xl="{span:24}">
                            <h4>近10年项目统计</h4>
                            <ve-histogram
                                :extend="salesExtend"
                                :data="saleData"
                                :colors="['red', 'blue', 'green']"
                                :legend-visible="false"
                                judge-width
                                height="320px"
                            />
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template>
<script>
import VePie from 'v-charts/lib/pie'
import VeHistogram from 'v-charts/lib/histogram.min'
import 'echarts/lib/component/dataZoom'
export default {
    components: {
        // VeLine,      //折线图
        VeHistogram,    //柱状图
        VePie,          //饼状图
    },
    data () {
        this.salesExtend = {

            series(v) {
                v.forEach(i => {
                    i.symbol = '5'
                    i.barCategoryGap = 40
                })
                return v
            },
            legend: {
                data: ['国家级', '省级', '校级'],
            },
            xAxis:[
                {
                    type : 'category',
                }
            ]
        },
        this.chartExtend = {
            series: {
                center: ['50%', '55%']
            },
            legend: {
              orient: 'horizontal',
              top: 8,
            },
        }
        return {
            chartSettings: { type: 'pie',offsetY: '50%',radius:'55' },
            chartData: {
                // columns: ['key', 'value'],
                // rows: [
                //     { key: '国家级', value: 7 },
                //     { key: '省级', value: 1 },
                //     { key: '校级', value: 4 },
                // ]
            },
            saleData: {
                // columns: ['立项年份', '国家级','省级','校级'],
                // rows: [
                // { 立项年份: '2011', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2012', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2013', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2014', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2015', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2016', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2017', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2018', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2019', 国家级: 368,省级:222,校级:152 },
                // { 立项年份: '2020', 国家级: 368,省级:222,校级:152 },
                // ]
            },
        }
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Projectstatistics.css';
</style>
<style>
.Projectstatistics .el-col{
    padding: 0 12px;
}
.margin-bottom-24{
    margin-bottom:24px;
}
</style>